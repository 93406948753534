import React from "react";
import {useStore} from "../../Store";

import Player from "./Player";

const OutputPlayers = ({showDelete=true}) => {
    const {players} = useStore();

    return (
        <>
            <section className={(showDelete) ? "players" : "no-remove players" }>
                {players.map((player) => {
                    return (
                        <Player playerName={player.name} key={player.id} playerId={player.id} showDelete={showDelete} />
                    )
                })}
            </section>
        </>
    );
};

export default OutputPlayers;