import React, {useState, useCallback, useRef} from "react";
import {useStore} from "../../Store";

const AddTask = () => {
    const {tasksAdd} = useStore();
    const form = useRef();
    const [newTask, setNewTask] = useState({
        name: "",
        description: ""
    });

    const handleInput = (event) => {
        setNewTask({...newTask, [event.target.name]:event.target.value})
    }

    const handleSubmit = useCallback(event => {
        event.preventDefault();
        tasksAdd(newTask.name, newTask.description);
        setNewTask({name: "", description: ""})
    }, [tasksAdd, newTask]);

    return (
        <>
            <form onSubmit={handleSubmit} ref={form} className="task-form">
                <label htmlFor="name">Name der Aufgabe:</label>
                <input type="text" name="name" id="name" maxLength={50} required onChange={event => handleInput(event)} value={newTask.name} />
                <label htmlFor="description">Beschreibung (Optional):</label>
                <input type="text" name="description" id="description" maxLength={60} onChange={event => handleInput(event)} value={newTask.description} />
                <input type="submit" value="Aufgabe hinzufügen" />
            </form>
        </>
    );
};

export default AddTask;