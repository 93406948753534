import React, {useCallback, useEffect, useState} from "react";
import {useStore} from "../Store";
import { useLocation, useNavigate } from "react-router-dom";

import IntroInfos from "../components/shared/IntroInfos";
import AddPlayer from "../components/players/AddPlayer";
import OutputPlayers from "../components/players/OutputPlayers";
import AddTask from "../components/tasks/AddTask";
import OutputTaskPills from "../components/tasks/OutputTaskPills";

const CreateGame = () => {
    const redirectTo = useNavigate();
    const {players, playersClear, tasks, tasksClear} = useStore();
    const location = useLocation();
    const [editMode, setEditMode] = useState();

    const clearAll = useCallback(() => {
        playersClear();
        tasksClear();    
    }, [playersClear, tasksClear]);

    useEffect(() => {
        if (location.state === null) {
            return;
        }

        if (location.state?.clearOnLoad === false) {
            setEditMode(true);
            return;
        } else if (location.state?.clearOnLoad === true) {
            window.history.replaceState({}, document.title)
            setEditMode(false);
            clearAll();
        }
        
    }, [clearAll, location, setEditMode]);
    

    return (
        <>
            <IntroInfos 
              Headline={`Spiel ${(editMode) ? "bearbeiten" : "erstellen"}`}
              Text={"Nun musst die Personen und Aufgaben definieren. Nachdem du das gemacht hast kann es auch schon los gehen!"}
            />
            <h3>Hinzufügen der Spieler</h3>
            <AddPlayer />
            {players.length > 0 ? 
                <>
                    <h4><b>Hinzugefügte Personen:</b></h4> 
                    <OutputPlayers/>
                </> 
            : ""}
            <hr />
            <h3>Hinzufügen der Aufgaben</h3>
            <AddTask />
            {tasks.length > 0 ? 
                <>
                    <h4><b>Hinzugefügte Aufgaben:</b></h4> 
                    <OutputTaskPills />
                </> 
            : ""}
            {(tasks.length > 0 & players.length > 0) ? 
                <>
                    <hr />
                    <h2>Alles hinzugefügt? Dann mal los!</h2>
                    <div className="button-group horizontal">
                        <button onClick={() => redirectTo("/show-tasks")}>Aufgaben verteilen</button>
                        <button className="secondary" onClick={() => {clearAll(); setEditMode(false)}}>Spiel zurücksetzen</button>
                    </div>
                </>
            : ""}
        </>
    );
};

export default CreateGame;