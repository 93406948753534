import React from "react";
import RandomGif from "./RandomGif";

import HomeRoute from "../routes/HomeRoute";
import CreateGame from "../routes/CreateGame";
import ShowTasks from "../routes/ShowTasks";
import Imprint from "../routes/Imprint";
import GDPR from "../routes/GDPR";

import {Routes, Route, Link, useLocation} from "react-router-dom";

const MussNicht = () => {
  const location = useLocation();

  return (
    <>
      <header>
        <a href="/">
          <RandomGif />
        </a>
      </header>
      <main>
        {location.pathname === "/imprint" || location.pathname === "/privacy-policy" ? "" : <h1>Muss nicht!</h1>}
          <Routes>
            <Route path="/" element={<HomeRoute />} />
            <Route path="/create-game" element={<CreateGame />} />
            <Route path="/show-tasks" element={<ShowTasks />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/privacy-policy" element={<GDPR />} />
          </Routes>
      </main>
      <footer>
        <Link to={"imprint"}>Impressum</Link>
        <Link to={"privacy-policy"}>Datenschutz</Link>
      </footer>
      
    </>
  );
};

export default MussNicht;