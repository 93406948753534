import React, { useEffect, useState } from "react";

const RandomGif = () => {
    const [gifNumber, setgifNumber] = useState("");
    
    useEffect(() => {
        let gif = Math.floor(Math.random() * (5 - 1) + 1);
        setgifNumber(gif);
    }, []);
    
    return (
        <>
            <div className="random-gif">
                <img src={`assets/video/gif0${gifNumber}.gif`} alt="" />
            </div>
        </>
    );
};

export default RandomGif;