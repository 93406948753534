import React from "react";

const Imprint = () => {
    return (
        <>
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Julian Kohl<br />
            Kontrastreich &ndash; Webentwicklung aus Leidenschaft<br />
            Hinter den G&auml;rten, 1<br />
            54426 Heidenburg</p>

            <h2>Kontakt</h2>
            <p>Telefon: 015172742153<br />
            E-Mail: dev@c0ff.net</p>

            <h2>Gestaltung & Programmierung</h2>
            <p>Kontrastreich - Webentwicklung aus Leidenschaft <br />
            <a href="https://kontrastreich.eu">kontrastreich.eu</a></p>

            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            DE321672417</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

            <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
        </>
    );
};

export default Imprint;