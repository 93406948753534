import React from "react";

const Task = ({playerName, name, description}) => {
    
    return (
        <>
            <div className="task-wrapper">
                <h2>{name}</h2>
                {description !== "" ? <h3>{description}</h3> : ""}
                <p><b>{playerName}</b></p>
            </div>
            
        </>
    );
};

export default Task;