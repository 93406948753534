import React from "react";
import {useStore} from "../../Store";

import TaskPill from "./TaskPill";

const OutputTaskPills = ({showDelete=true}) => {
    const {tasks} = useStore();

    return (
        <>
            <section className={(showDelete) ? "players" : "no-remove players" }>
                {tasks.map((task) => {
                    return (
                        <TaskPill taskName={task.name} key={task.id} taskId={task.id} showDelete={showDelete} />
                    )
                })}
            </section>
        </>
    );
};

export default OutputTaskPills;