import React, {useEffect, useState} from "react";

const Accordion = ({title, children, defaultOpen=false, readMoreText, readMoreTextAccOpen}) => {
    const [open, setOpen] = useState(null);

    useEffect(() => {
        if (!defaultOpen)
            return;

        setOpen(true)
    }, [defaultOpen, setOpen])

    return (
        <>
            <details open={defaultOpen ? true : false} className={`accordion ${open ? "open" : ""}`}>
                <summary onClick={() => setOpen(open ? false : true)}>{title} {readMoreText ? <span>{open ? readMoreTextAccOpen : readMoreText}</span> : ""}</summary>
                <div className="acc-inner-content">
                    {children}
                </div>
            </details>
        </>
    );
};

export default Accordion;