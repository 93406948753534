import React from "react";

const IntroInfos = ({Headline, Text}) => {
    return (
        <>
            <section className="intro">
                <h2>{Headline}</h2>
                <p>
                    {Text}
                </p>
            </section>
        </>
    );
};

export default IntroInfos;