import React from "react";
import { useStore } from "../Store";
import { useNavigate } from "react-router-dom";

import IntroInfos from "../components/shared/IntroInfos";
import Task from "../components/tasks/Task";

const ShowTasks = () => {
    const navigateTo = useNavigate();
    const {players, tasks} = useStore();

    return (
        <>
            <IntroInfos 
              Headline={"Die Aufgaben wurden verteilt!"}
              Text={"Nun gibts kein zurück und keine Verhandlung. Hier wird sich nicht gedrückt! Nun kümmert euch um eure Aufgaben."}
            />
            <section className="result">
                {
                    tasks.map((task) => {
                        return (
                            <Task playerName={players[Math.floor((Math.random() * players.length))].name} name={task.name} description={task.description} key={task.id}/>
                        )
                    })
                }
            </section>
            <hr />
            <h2>Alle Aufgaben erledigt? Was nun?</h2>
            <div className="button-group horizontal">
                <button onClick={() => navigateTo("/show-tasks", {replace: true})}>Aufgaben neu verteilen</button>
                <button onClick={() => navigateTo("/create-game", {replace: true, state: {clearOnLoad: false}})}>Spiel bearbeiten</button>
                <button onClick={() => navigateTo("/create-game", {replace: true, state: {clearOnLoad: true}})}>Neues Spiel</button>
            </div>
        </>
    );
};

export default ShowTasks;