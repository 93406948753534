import React from "react";
import {createRoot} from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./assets/css/muss-nicht.css";
import "./../node_modules/pattern.css/dist/pattern.min.css";

import MussNicht from "./components/MussNicht";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MussNicht />
    </BrowserRouter>
  </React.StrictMode>,
);