import create from "zustand";
import { persist } from "zustand/middleware"
import produce from "immer";
import uuid from "react-uuid";

export const useStore = create(persist(
    (set) => ({
        players: [],
        playersAdd: (payload) => set(
            produce((draft) => {
                draft.players.push({
                    id: uuid(),
                    name: payload,
                });
            })
        ),
        playersRemove: (payload) => set(
            produce((draft) => {
                const playerIndex = draft.players.findIndex(player => player.id === payload);
                draft.players.splice(playerIndex, 1);
            })
        ),
        playersClear: () => set(({players: []})),
        
        tasks: [],
        tasksAdd: (name, desc) => set(
            produce((draft) => {
                draft.tasks.push({
                    id: uuid(),
                    name: name,
                    description: desc,
                });
            })
        ),
        tasksRemove: (payload) => set(
            produce((draft) => {
                const taskIndex = draft.tasks.findIndex(player => player.id === payload);
                draft.tasks.splice(taskIndex, 1);
            })
        ),
        tasksClear: () => set(({tasks: []})),

    }),
    {
        name: "muss-nicht",
    }
));