import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import IntroInfos from "../components/shared/IntroInfos";
import OutputPlayers from "../components/players/OutputPlayers";
import Accordion from "../components/shared/Accordion";
import OutputTaskPills from "../components/tasks/OutputTaskPills";


const HomeRoute = () => {
    const [hasSavedGame, setHasSavedGame] = useState(false);
    const navigateTo = useNavigate();

    //Check if prev. players saved in local store
    useEffect(() => {
        let savedLocalData = JSON.parse(localStorage.getItem("muss-nicht"));
        if (savedLocalData && savedLocalData.state.players.length > 0 && savedLocalData.state.tasks.length > 0) {
            setHasSavedGame(true);
        } else {
            return;
        }
    }, []);

    return (
        <>
            {hasSavedGame ? 
                <>
                    <IntroInfos 
                        Headline={"Willkommen zurück!"}
                        Text={"Deine letze Runde wurde gespeichert!"}
                    />
                    <h3>Dein letzes Spiel</h3>
                    <p>Du kannst nun dein letzes Spiel wiederholen oder bearbeiten. Wenn du das Spiel bearbeitest kannst Personen oder Aufgaben hinzufügen oder entfernen. Du kannst natürlich auch ein Neues Spiel starten.</p>
                    
                    <p><b>Details zum letzen Spiel:</b></p>
                    <Accordion title={"Spieler"} readMoreText={"Anzeigen"} readMoreTextAccOpen={"Ausblenden"}>
                        <h4>Diese Personen waren in der letzen Runde dabei:</h4>
                        <OutputPlayers showDelete={false} />
                    </Accordion>
                    <Accordion title={"Aufgaben"} readMoreText={"Anzeigen"} readMoreTextAccOpen={"Ausblenden"}>
                        <h4>Diese Aufgaben gab es in der letzen Runde:</h4>
                        <OutputTaskPills showDelete={false} />
                    </Accordion>
                    <hr />
                    <h3>Was möchtest du tun?</h3>
                    <div className="button-group horizontal">
                        <button onClick={() => navigateTo("/show-tasks", {replace: true})}>Spiel wiederholen</button>
                        <button onClick={() => navigateTo("/create-game", {replace: true, state: {clearOnLoad: false}})}>Spiel bearbeiten</button>
                        <button onClick={() => navigateTo("/create-game", {replace: true, state: {clearOnLoad: true}})}>Neues Spiel</button>
                    </div>
                        
                </> : 
                <>
                    <IntroInfos 
                        Headline={"Nie wieder Streit bei der Vergabe von Aufgaben!"}
                        Text={'"Muss nicht!" macht das verteilen von Aufgaben leichter. Du hast die Möglichkeit Personen und Aufgaben einzugeben, diese werden dann per Zufall zugeordnet. Das bedeudet: kein Streit, kein Stress, keine Disskusion. Wer eine Aufgabe zugeteilt bekommt muss sie erledigen!'}
                    />
                    <p><b>Also, ab gehts! Klick auf "Neues Spiel" und leg los!</b></p>
                    <button onClick={() => navigateTo("/create-game", {replace: true, state: {clearOnLoad: true}})}>Neues Spiel</button>
                </>
            }
        </>
    );
};

export default HomeRoute;