import React, {useState, useCallback} from "react";
import {useStore} from "../../Store";

const AddPlayer = () => {
    const {playersAdd} = useStore();
    const [playerName, setPlayerName] = useState("");

    const handleSubmit = useCallback(event => {
        event.preventDefault();
        playersAdd(playerName);
        setPlayerName("");
    }, [playerName, playersAdd]);

    return (
        <>
            <form onSubmit={handleSubmit} className="player-form">
                <label htmlFor="name">Name:</label>
                <input type="text" name="name" id="name" maxLength={50} required onChange={event => setPlayerName(event.target.value)} value={playerName} />
              <input type="submit" value="Hinzufügen" />
            </form>
        </>
    );
};

export default AddPlayer;